expense-details,
campaign-details-drawer,
program-details-drawer,
goal-details-drawer,
metric-details-drawer {
  width: 690px !important;
  right: 0;
  box-shadow: -5px 0px 15px rgba(129, 160, 198, 0.4);

  &:not(.active) {
    cursor: pointer;

    &:hover {
      right: 10px;
    }

    .details-page-wrapper > * {
      pointer-events: none;
    }
  }

  .details-page-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .details-form-field {
    display: flex;
    padding: 0 15px;

    .icon-container {
      margin-top: 15px;
      margin-right: 10px;

      fa-icon {
        font-size: 20px;
        --fa-primary-color: var(--blue-grey-medium);
        --fa-secondary-color: var(--blue-grey-medium);

        &.size-16 {
          font-size: 16px;
        }

        &.size-17 {
          font-size: 17px;
        }

        &.size-21 {
          font-size: 21px;
        }

        &.size-22 {
          font-size: 22px;
        }
      }

      + hierarchy-select,
      + parent-hierarchy-select,
      + tags-control,
      + attachments-list,
      + amount-status-switch,
      + .p-form-field-outline {
        width: calc(100% - 40px);
      }
    }

    .p-form-field-outline {
      object-name {
        overflow: hidden;

        .wrapping-text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.readonly {
        pointer-events: none;

        .mat-form-field-outline {
          color: transparent !important;
        }

        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            color: transparent;
          }
        }
      }

      &.mat-form-field-disabled {
        opacity: 1;

        &.mat-form-field-appearance-outline {
          .mat-select-value-text {
            font-weight: 500;
            color: rgba(183, 193, 208, 1);
          }
        }

        .mat-form-field-outline {
          color: rgba(222, 230, 241, 1)!important;
          opacity: 0.3;
        }

        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            color: transparent;
          }
        }
      }

      .mat-select-arrow-wrapper {
        width: 10px;

        .mat-select-arrow {
          color: var(--blue-grey-medium);
        }
      }

      &.mat-form-field-appearance-outline {
        .mat-input-element,
        .mat-select-value-text {
          font-weight: 500;
        }
      }
    }

    unique-id {
      width: 100%;
      margin-left: 5px;

      fa-icon.finger-icon {
        font-size: 20px !important;
        margin-top: 20px !important;
        margin-right: 10px;
        --fa-primary-color: var(--blue-grey-medium) !important;
        --fa-secondary-color: var(--blue-grey-medium) !important;

        + .content {
          margin-left: 17px;
        }
      }

      .external-value {
        margin-top: 6px;
      }

      .copy-icon {
        font-size: 20px;
        margin-top: 20px;
        --fa-primary-color: var(--primary) !important;
        --fa-secondary-color: var(--primary) !important;
      }
    }

    attachments-list .attachments-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 56px;
    }

    &.parent {
      margin-bottom: 17px;
    }

    .related-expenses {
      display: flex;
      height: 56px;

      .expenses-number {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: var(--navy-blue);
      }
    }

    .mat-form-field-appearance-outline.readonly .mat-form-field-outline {
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border: none;
      }
    }

    &.attachments {
      align-items: center !important;

      .icon-container {
        margin-top: 5px;
      }
    }

    .mat-form-field-label {
      font-weight: 400 !important;
    }
  }

  .details-content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    &.tab-content-wrap > * {
      display: block;
      transition: opacity .6s, visibility .6s;

      &.visible {
        opacity: 1;
        visibility: visible;
      }

      &:not(.visible) {
        height: 0;
        overflow: hidden;
        opacity: 0;
      }
    }
  }
}

.drawer-name-tooltip {
  top: -290px;
  right: -10px;
}

header.ceg-budget-details .app-navigation {
  min-height: 125px;
}

drawer-tab-switch {
  + .details-content {
    padding-top: 60px;
  }
}

.tab-content-container {
  padding: 0 30px;
}

.area-holder {
  .details-form-field:first-child {
    margin-bottom: 25px;
  }

  mat-form-field.mat-form-field .mat-form-field-infix {
    padding: .3em 0;
    border-top-width: 4px;
  }

  textarea {
    min-height: 72px;
  }
}

.details-panel {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 15px 15px;

  + .details-panel:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--blue-grey-medium);
  }
}

.planned-field {
  position: relative;

  .currency-select {
    position: absolute;
    left: 68px;
    bottom: 27px;
    width: 63px;
    border-right: 1px solid var(--blue-disabled);
    z-index: 10;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;

    &.mat-select-disabled {
      pointer-events: none;
      border-color: transparent;

      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: transparent;
        }
      }
    }

    &:hover {
      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: var(--primary);
        }
      }
    }
  }

  .mat-select-trigger {
    height: 50px;
  }

  .mat-form-field-infix input {
    width: 130px;
    padding-left: 84px;
  }

  .mat-select-value {
    padding: 9px 0 11px;
    width: 40px;
  }

  .mat-select-arrow-wrapper {
    padding-right: 8px;

    .mat-select-arrow {
      position: relative;
      height: 6px;
      width: 10px;
      margin: 0 0 3px 0;
      border: none;
      background: none;
      color: var(--blue-grey-medium);

      &:before,
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 4px;
        height: 8px;
        width: 2px;
        border-radius: 1px;
        background-color: currentColor;
        transform-origin: 50% calc(100% - 1px);
      }

      &:after {
        transform: rotateZ(48deg);
      }

      &:before {
        transform: rotateZ(-48deg);
      }
    }
  }

  .mat-select-disabled {
    .mat-select-value {
      color: var(--navy-blue);
      padding: 4px 0 0;
    }
  }

  mat-hint {
    &.status-less-mode-hint {
      margin-left: 85px;
    }
  }
}

.timeframe-select {
  .mat-option.mat-option-disabled {
    color: var(--blue-grey-medium);

    fa-duotone-icon {
      font-size: 16px;
      --fa-primary-color: var(--blue-grey-medium);
      --fa-secondary-color: var(--blue-grey-medium);
    }
  }
}

.details-form-field add-object-link button.link-button {
  font-size: 15px;
}

.unverified-box {
  padding: 20px 30px 10px;

  .holder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 12px 18px 12px 2px;
    background-color: rgba(255, 153, 1, .1);
    border-radius: 8px;
    font-family: 'Montserrat', serif;
  }

  .info-holder {
    width: 100%;
    max-width: 375px;
    color: var(--navy-blue);
    font-weight: 500;

    .title {
      display: block;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 4px;
    }

    p {
      font-size: 11px;
      line-height: 17px;
      padding: 0;
      opacity: .6;
    }
  }

  .mat-button {
    margin-left: auto;
  }
}

.panel-floating-title {
  position: absolute;
  top: -7px;
  left: 65px;
  margin: 0;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  color: var(--mat-default);
  background: var(--corporate-white);
}
